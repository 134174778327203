<template>
  <div class="container">
    <div class="row" v-if="$store.state.requiredNetwork !== $store.state.currentNetwork">
      <div class="text-center">
        <p>Please connect to SmartBCH.</p>
        <button class="btn btn-primary" v-on:click="$store.dispatch('switchNetwork')">Switch network</button>
      </div>
    </div>
    <template v-if="$store.state.requiredNetwork === $store.state.currentNetwork">
      <div class="row market-navigation">
        <div class="d-flex justify-content-center">
          <div class="btn-group btn-group-lg" role="group" aria-label="Navigation">
            <button type="button" class="btn btn-outline-primary" v-on:click="viewWallet = false;onlyMine = false;updateList();">All</button>
            <button type="button" class="btn btn-outline-primary" v-on:click="viewWallet = false;onlyMine = true;updateList();">My listings</button>
            <button type="button" class="btn btn-outline-primary" v-on:click="viewWallet = true;updateList();">Unlisted</button>
          </div>
          &nbsp;
          <div class="btn-group btn-group-lg" role="group" aria-label="Navigation">
            <button type="button" class="btn btn-outline-primary" v-on:click="showMyBids();">My bids</button>
            <button type="button" class="btn btn-outline-primary" v-on:click="showReceivedBids();">Received bids</button>
          </div>
          &nbsp;
          <div class="btn-group btn-group-lg" role="group" aria-label="Navigation">
            <a class="btn btn-outline-secondary" href="https://t.me/cryptoratlistings" target="_blank">📡</a>
          </div>
        </div>
      </div>
      <div class="row" v-if="sortPriceButtonLabel">
        <div class="d-flex justify-content-center">
          <span class="btn floor">Floor price: {{ floor[currency] }} {{ currencies[currency] }}</span>
        </div>
        <div class="d-flex justify-content-center">
          <div class="btn-group btn-group-sm" role="group" aria-label="Navigation">
            <span class="btn btn-secondary">Sorting: </span>
            <button type="button" v-bind:class="{active: sortPrice, btn: true, 'btn-outline-secondary': true}" v-on:click="sortIq = 0;sortPrice = (sortPrice + 1) % 3;updateList()">{{sortPriceButtonLabel}}</button>
            <button type="button" v-bind:class="{active: sortIq, btn: true, 'btn-outline-secondary': true}" v-on:click="sortPrice = 0;sortIq = (sortIq + 1) % 3;updateList()">{{sortIqButtonLabel}}</button>
          </div>

          &nbsp;

          <div class="btn-group btn-group-sm" role="group" aria-label="Navigation" v-if="tokenEnabled">
            <span class="btn btn-secondary">Currency: </span>
            <button type="button" v-bind:class="{active: currency === zero, btn: true, 'btn-outline-secondary': true}" v-on:click="currency = zero;updateList()">BCH</button>
            <button type="button" v-bind:class="{active: currency === nexusToken, btn: true, 'btn-outline-secondary': true}" v-on:click="currency = nexusToken;updateList()">NXS</button>
          </div>
        </div>
      </div>
      <div class="row rats" v-if="displayListings">
        <div class="col-lg-2 col-md-3 col-sm-6 text-center" v-for="listing in displayListings" :key="listing.salt">
          <template v-if="listing.ca === ratContract">
            <router-link v-bind:to="'/rat/id/' + listing.tokenId">
              {{ listing.metadata.name }}<br/>
              <p>
                <img v-bind:src="listing.image" class="img-fluid rat-image" />
              </p>
              <p style='height:60px;'>
                IQ: {{ listing.metadata['rat-iq'] }} 🧠 &nbsp;
                <span v-if="listing.price" class="btn btn-outline-primary">
                  <strong>{{listing.priceDisplay}} {{ listing.currencySymbol }}</strong>
                </span>
              </p>
            </router-link>
          </template>
          <template v-if="listing.ca !== ratContract">
            <router-link v-bind:to="'/' + listing.ca + '/token/' + listing.tokenId">
              {{ listing.metadata.name }} #{{ listing.tokenId }}<br/>
              <img v-bind:src="listing.image" class="img-fluid rat-image" /><br/>
              <p style='height:60px;'>
                <span v-if="listing.price" class="btn btn-outline-primary">
                  <strong>{{listing.priceDisplay}} {{ listing.currencySymbol }}</strong>
                </span>
              </p>
            </router-link>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<style>
.btn.floor {
  color: #6c757d;
}
</style>

<script>
import {ethers} from "ethers";
import {nexusToken, nftCA} from "../../../config";
import erc20 from "../../../artifacts/contracts/Nexus.sol/Nexus.json";
import erc721 from "../../../artifacts/contracts/RatCollectibles.sol/NFT.json";
export default {
  data() {
    return {
      contract: nftCA,
      ratContract: nftCA,
      nexusToken: nexusToken,
      currency: ethers.constants.AddressZero,
      zero: ethers.constants.AddressZero,
      currencies: {},
      nfts: {},
      loadedList: [],
      listings: [],
      displayListings: [],
      sortPrice: 0,
      sortIq: 0,
      onlyMine: false,
      viewWallet: false,
      sortPriceButtonLabel: 'Price',
      sortIqButtonLabel: 'IQ',
      floor: '-',
      updating: false,
    }
  },
  watch: {
    '$store.state.provider': function () {
      this.loadMarket();
    },
    '$store.state.wallet': function () {
      this.updateList();
    }
  },
  mounted() {
    if (this.$store.state.provider) {
      this.loadMarket();
    }
  },
  methods: {
    loadMarket() {
      fetch(this.api_url + '/original/market').then((result) => {
        return result.json();
      }).then(async (listings) => {
        this.currencies[ethers.constants.AddressZero] = 'BCH';
        for (let key in listings) {
          if (listings[key] === null) continue;
          if(typeof this.loadedList[listings[key].salt] != 'undefined') {
            continue;
          }
          this.loadedList[listings[key].salt] = true;

          let image = '';
          switch (listings[key].ca) {
            case nftCA:
              image = 'https://api.cryptor.at/original/rat/' + listings[key].tokenId;
              break;
            case '0x1De097da3Fe906137ece38a0583dA427b99e566e':
              image = 'https://raw.githubusercontent.com/shadowkite/rat-collectibles/main/images/' + listings[key].tokenId + '.webp';
              break;
            case '0xD710EF1E6aDfb5f81B3598f9Eea70512c5B100F5':
              image = 'https://raw.githubusercontent.com/shadowkite/council-of-frogs/master/images/' + listings[key].tokenId + '.png';
              break;
            case '0xC351940537C522E2c5f1bb2b0139e156BB02e3fe':
              image = 'https://shadowkite.github.io/images/' + listings[key].tokenId + '.png';
              break;
          }

          if (typeof this.currencies[listings[key].currency] == 'undefined') {
            let tokenContract = new ethers.Contract(listings[key].currency, erc20.abi, this.$store.state.provider);
            this.currencies[listings[key].currency] = await tokenContract.symbol();
          }

          let nftContract = new ethers.Contract(listings[key].ca, erc721.abi, this.$store.state.provider);
          if (typeof this.nfts[listings[key].ca] == 'undefined') {
            this.nfts[listings[key].ca] = await nftContract.name();
          }

          let listing = listings[key];
          listing.currencySymbol = this.currencies[listings[key].currency];
          listing.nftName = this.nfts[listings[key].ca];
          listing.image = image;
          listing.priceDisplay = ethers.utils.formatEther(listings[key].price);
          this.$store.state.marketContract.tradeCompleted(listings[key].hash).then(completed => {
            if (completed) return;
            nftContract.tokenURI(listings[key].tokenId).then((uri) => {
              return fetch(uri).then((result) => {
                return result.json();
              }).then(data => {
                listing.metadata = data;
                this.listings.push(listing);
              }).then(() => {
                this.updateList();
              });
            });
          });
        }
      });
    },
    showMyBids() {
      // --
    },
    showReceivedBids() {
      // --
    },
    updateList() {
      if (!this.updating) {
        this.updating = true;
        let displayListings = [];
        if (this.viewWallet) {
          var wallet = [];
          for (var key in this.$store.state.wallet) {
            var found = false;
            for(var item in this.listings) {
              if(this.listings[item].ca.toLowerCase() === this.contract.toLowerCase()
                  && this.listings[item].tokenId === this.$store.state.wallet[key].id) {
                found = true;
              }
            }
            if(!found) {
              wallet.push({
                ca: nftCA,
                tokenId: this.$store.state.wallet[key].id,
                image: this.$store.state.wallet[key].metadata.image,
                metadata: this.$store.state.wallet[key].metadata,
                salt: nftCA + this.$store.state.wallet[key].id
              });
            }
          }
          displayListings = wallet;
        } else {
          if (this.onlyMine) {
            displayListings = this.listings.filter(listing => (listing.owner.toLowerCase() === this.$store.state.address.toLowerCase()));
          } else {
            displayListings = this.listings;
          }
        }

        this.sortPriceButtonLabel = 'Price';
        this.sortIqButtonLabel = 'IQ';
        if (!this.viewWallet && this.sortPrice > 0) {
          displayListings.sort(function (a, b) {
            if (a.price === b.price) {
              return 0;
            }
            if (a.price > b.price) {
              return 1;
            }
            return -1;
          });
          if (this.sortPrice === 2) {
            displayListings.reverse();
            this.sortPriceButtonLabel = 'Price ⇧';
          } else if (this.sortPrice === 1) {
            this.sortPriceButtonLabel = 'Price ⇩';
          }
        } else if (!this.viewWallet && this.sortIq > 0) {
          displayListings.sort(function (a, b) {
            if (a.metadata['rat-iq'] === b.metadata['rat-iq']) {
              return 0;
            }
            if (a.metadata['rat-iq'] > b.metadata['rat-iq']) {
              return 1;
            }
            return -1;
          });
          if (this.sortIq === 1) {
            displayListings.reverse();
            this.sortIqButtonLabel = 'IQ ⇧';
          } else if (this.sortIq === 2) {
            this.sortIqButtonLabel = 'IQ ⇩';
          }
        } else {
          if (this.viewWallet) this.sortPriceButtonLabel = false;
          if (!this.viewWallet) this.sortPriceButtonLabel = 'Price';
          displayListings.sort(function (a, b) {
            if (a.tokenId === b.tokenId) {
              return 0;
            }
            if (a.tokenId > b.tokenId) {
              return 1;
            }
            return -1;
          });
        }
        if (this.reverse) {
          displayListings.reverse();
        }
        /*this.displayListings = displayListings.filter(listing => {
          return listing != null;
        });*/
        this.displayListings = displayListings.filter(listing => {
          return (listing.currency == null || listing.currency === this.currency);
        });
        this.updating = false;
      }
    }
  }
}
</script>

<style>
div.row.market-navigation {
  margin-bottom: 5px;
  padding-bottom: 5px;
}
</style>